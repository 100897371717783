import React, { useState, useEffect } from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Modal, Button, Grid, Typography, IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { MintBtn } from '../contract-btn/contractBtn'
import { textAlign } from '@material-ui/system'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#748FFC',
        },
    },
    typography: {
        body1: {
            color: '#FFF',
        },
    },
})

const CustomModal = ({ isOpen, onClose }) => {
    const [mintAmount, setMintAmount] = useState(5)
    const [isFormValid, setIsFormValid] = useState(false)

    const handleMintAmountChange = (value) => {
        if (value >= 0) {
            setMintAmount(value)
        }
    }

    const handleIncrement = (value) => {
        if (value < 20) {
            handleMintAmountChange(mintAmount + 1)
        }
    }

    const handleDecrement = (value) => {
        if (value > 1){
            handleMintAmountChange(mintAmount - 1)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if (isFormValid) {
            // Handle form submission logic here
        }
    }

    const validateForm = () => {
        if (mintAmount > 0) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }

    useEffect(() => {
        validateForm()
    }, [mintAmount])

    return (
        <ThemeProvider theme={theme}>
            <Modal open={isOpen} onClose={onClose} backgroundColor="#6C63FF">
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'black',
                        backgroundImage:
                            "url('/images/nft-game/gradient_glow_large_2.png')",
                        backgroundPosition: '50% 50%',
                        border: '2px solid #000',
                        boxShadow: theme.shadows[5],
                        padding: theme.spacing(2, 4, 3),
                        borderRadius: theme.spacing(2),
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img
                            src="/images/zkbirds_logo_mid.png"
                            width={300}
                            height={'auto'}
                            style={{
                                marginBottom: '20px',
                            }}
                        ></img>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={10} alignItems="center">
                            <Grid item xs={12} sm={6}>
                                <img
                                    src="/images/egg_crack.png"
                                    alt="Image description"
                                    style={{
                                        width: '300',
                                        height: 'auto',
                                        marginLeft: '15%',
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                container
                                direction="row"
                                alignItems="center"
                            >
                                <Grid item xs={12}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            borderRadius: 20,
                                            marginLeft: '15%',
                                            marginBottom: '5%',
                                            width: '100%',
                                        }}
                                    >
                                        <IconButton
                                            onClick={()=>{handleDecrement(mintAmount)}}
                                            disabled={mintAmount <= 0}
                                        >
                                            <RemoveIcon
                                                style={{
                                                    color: '#fff',
                                                    fontSize: 40,
                                                }}
                                            />
                                        </IconButton>
                                        <Typography
                                            variant="h5"
                                            style={{
                                                color: '#fff',
                                                fontSize: 40,
                                                width: '40px',
                                                height: '40px',
                                                lineHeight: '40px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {mintAmount}
                                        </Typography>
                                        <IconButton
                                            onClick={() => {
                                                handleIncrement(mintAmount)
                                            }}
                                            style={{
                                                marginLeft: '7px',
                                            }}
                                        >
                                            <AddIcon
                                                style={{
                                                    color: '#fff',
                                                    fontSize: 40,
                                                }}
                                            />
                                        </IconButton>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                  <MintBtn mintAmount={mintAmount}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Modal>
        </ThemeProvider>
    )
}

export default CustomModal
