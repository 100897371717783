import { useDispatch } from 'react-redux'
import { walletModalShow } from '../../redux/counterSlice'
import { useMetaMask } from 'metamask-react'
import { useEffect } from 'react'

const productChainId = '0x144'

const goreliChainId = '0x5'
const zksyncTestnetChainId = '0x118'
const zksyncChainId = '0x144'

const zksyncChainParameter = {
    chainName: 'zkSync Era Mainnet',
    chainId: '0x144',
    blockExplorerUrls: 'https://explorer.zksync.io',
    rpcUrls: 'https://mainnet.era.zksync.io',
    nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
    },
}

const zksyncTestNetChainParameter = {
    chainName: 'zkSync Era Testnet',
    chainId: '0x118',
    blockExplorerUrls: 'https://goerli.explorer.zksync.io',
    rpcUrls: 'https://testnet.era.zksync.dev',
    nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
    },
}


export default function WalletButton() {
    const dispath = useDispatch()
    const {
        status,
        connect,
        account,
        chainId,
        ethereum,
        switchChain,
        addChain,
    } = useMetaMask()

    const isChainAvailable = async (chainId) => {
        const currentChainId = await ethereum.request({ method: 'eth_chainId' })
        return currentChainId === chainId
    }

    const walletHandler = () => {
        if (status === 'unavailable') {
            dispath(walletModalShow())
        }
    }

    useEffect(() => {
        if (window.ethereum) {
            window.ethereum
                .request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: zksyncChainParameter.chainId,
                            chainName: zksyncChainParameter.chainName,
                            rpcUrls: [zksyncChainParameter.rpcUrls],
                            blockExplorerUrls: [
                                zksyncChainParameter.blockExplorerUrls,
                            ],
                            nativeCurrency: {
                                name: zksyncChainParameter.nativeCurrency.name,
                                symbol: zksyncChainParameter.nativeCurrency
                                    .symbol,
                                decimals:
                                    zksyncChainParameter.nativeCurrency
                                        .decimals,
                            },
                        },
                    ],
                })
                .then(() => console.log('Successfully added network to wallet'))
                .catch((error) => console.error(error))
        }

        switchChain(productChainId)
    }, [])

    useEffect(() => {
        switchChain(productChainId)
    }, [status])

    return (
        <div key={status}>
            {status === 'initializing' && <div>Initializing</div>}
            {status === 'unavailable' && (
                <button
                    onClick={walletHandler}
                    className="js-wallet border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={24}
                        height={24}
                        className="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                    >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1"></path>
                    </svg>
                </button>
            )}
            {status === 'notConnected' && (
                <button
                    onClick={connect}
                    className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between  text-base dark:text-white lg:px-5 w-full"
                >
                    <a className="rounded-full bg-white py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume">
                        {' '}
                        Connect
                    </a>
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z" />
                </button>
            )}
            {status === 'connecting' && <div>Connecting...</div>}
            {status === 'connected' && (
                <button
                    disabled
                    className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between  text-base dark:text-white lg:px-5 w-full"
                >
                    <a className="rounded-full bg-white py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume">
                        {' '}
                        Connected
                    </a>
                </button>
            )}
        </div>
    )
}
