import { ethers } from 'ethers'
import { MerkleTree } from 'merkletreejs'
import keccak256 from 'keccak256'

function getWlList() {
    let wl = require('./wl.json')
    for (let i = 0; i < wl.length; ++i) {
        wl[i] = wl[i].toLowerCase()
        if (!ethers.utils.isAddress(wl[i])) {
            console.log(wl[i], 'is wrong address.')
            process.exit()
        }
    }
    wl = new Set(wl)
    wl = Array.from(wl)
    console.log('all address is correct !')
    return wl
}

function getMerkleProof(address) {
    const whitelistAddresses = getWlList()
    const leafNodes = whitelistAddresses.map((addr) => keccak256(addr))
    const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true })

    address = keccak256(address.toLowerCase())
    const merkleProof = merkleTree.getHexProof(address)

    return merkleProof
}

export { getWlList, getMerkleProof }
