import Footer from './footer'
import Wallet_modal from './modal/wallet_modal'
import BidsModal from './modal/bidsModal'
import BuyModal from './modal/buyModal'
import { useRouter } from 'next/router'
import HeaderZkbirds from './header/HeaderZkbirds'
import { ToastContainer } from 'react-toastify'

export default function Layout({ children }) {
    return (
        <>
            <ToastContainer />
            <HeaderZkbirds />
            <Wallet_modal />
            {/* <BidsModal /> */}
            {/* <BuyModal /> */}
            <main>{children}</main>
            <Footer />
        </>
    )
}
