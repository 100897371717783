import React from 'react'
import { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { Contract, Web3Provider, Provider } from 'zksync-web3'
import { onMintPressed } from './contractInteraction'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export function MintBtn(props) {
    const [currSigner, setSigner] = useState(null)
    const [currContract, setContract] = useState(null)
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        async function getContract() {
            try {
                const contractAddress =
                    '0x68bae7F492c18b2C2621769bc7AC36Dae906c649'
                const contractAbi = require('./abi/abi.json')
                const signer = new Web3Provider(window.ethereum).getSigner()
                setSigner(signer)
                const contract = new Contract(
                    contractAddress,
                    contractAbi,
                    signer
                )
                setContract(contract)
            } catch (err) {
                console.log(err)
                toast.error(err)
            }
        }
        void getContract()
    }, [])

    async function handleMint() {
        if (!currSigner) {
            toast.warning('Please connect wallet')
            return
        }
        setLoading(true)
        try {
            await onMintPressed(currContract, props.mintAmount, currSigner)
        } catch (err) {
            toast.error(err)
        }
        setLoading(false)
    }


    return (
        <>
            <button
                className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between  text-base dark:text-white lg:px-10 w-full"
                onClick={handleMint}
                disabled={isLoading}
            >
                <a
                    className="rounded-full bg-white py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
                    style={{
                        fontSize: '30px',
                        marginLeft: '3%',
                        width: '80%',
                    }}
                >
                    {isLoading ? (
                        <div className="flex justify-center items-center ">
                            <svg
                                className="animate-spin -ml-1 mr-3  w-9 text-accent"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="00 0 24 24"
                                style={{ marginLeft: '10px' }}
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                ></path>
                            </svg>
                        </div>
                    ) : (
                        'Mint'
                    )}
                </a>
            </button>
        </>
    )
}
