import Image from 'next/image'
import Link from 'next/link'
import DarkMode from '../mode/DarkMode'
import Logo from './../../public/images/logo.png'
import WhiteLogo from './../../public/images/zkbirds_logo.png'
import { v4 as uuidv4 } from 'uuid'
import { useRouter } from 'next/router'
import {
    isChildrenPageActive,
    isParentPageActive,
} from '../../utils/daynamicNavigation'
import { useEffect, useState } from 'react'
import WalletButton from '../wallet-btn/WalletButton'
import Modal from '../modal/mintModal'

export default function HeaderZkbirds() {
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase()
        setIsMobile(
            /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
                userAgent
            )
        )
    }, [])

    const route = useRouter()
    /* -------------------------------------------------------------------------- */
    /*                            daynamic navigations                            */
    /* -------------------------------------------------------------------------- */

    const homeClick = () => {
        // Check if current page is root page
        if (window.location.pathname === '/') {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        } else {
            // Navigate to root page
            window.location.href = '/'
        }
    }

    const aboutClick = () => {
        window.scrollTo({
            top: document.body.scrollHeight / 2.5,
            behavior: 'smooth',
        })
    }
    const roadmapClick = () => {
        window.scrollTo({
            top: (document.body.scrollHeight / 3) * 2,
            behavior: 'smooth',
        })
    }

    return (
        <>
            {/* main desktop menu sart*/}
            <header className='js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors'>
                <div className='flex items-center px-6 py-6 xl:px-12 '>
                    {!isMobile && (
                        <Link className='shrink-0' href='/'>
                            <a>
                                <div className='hidden dark:block'>
                                    <Image
                                        src={'/images/zkbirds_logo_small.png'}
                                        height={28}
                                        width={100}
                                        alt='Xhibiter | NFT Marketplace'
                                    />
                                </div>
                            </a>
                        </Link>
                    )}
                    {isMobile && <WalletButton />}
                    {/* End  logo */}

                    <div className='js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent'>
                        <nav className='navbar w-full'>
                            <ul className='flex justify-between'>
                                <li className='js-nav-dropdown group relative'>
                                    <button
                                        onClick={homeClick}
                                        className='text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full'
                                    >
                                        Home
                                    </button>
                                </li>
                                <li className='js-nav-dropdown group relative'>
                                    <button
                                        onClick={aboutClick}
                                        className='text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full'
                                    >
                                        About
                                    </button>
                                </li>
                                <li className='js-nav-dropdown group relative'>
                                    <button
                                        onClick={roadmapClick}
                                        className='text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full'
                                    >
                                        Roadmap
                                    </button>
                                </li>
                                {/* <li className="js-nav-dropdown group relative">
                                    <button
                                        onClick={aboutClick}
                                        className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"
                                    >
                                        Team
                                    </button>
                                </li> */}
                                <li className='js-nav-dropdown group relative'>
                                    <WalletButton />
                                </li>
                            </ul>
                        </nav>
                        {/* End menu for desktop */}
                    </div>

                    {/* header menu conent end for desktop */}
                </div>
                {/* End flex item */}
            </header>
            {/* main desktop menu end */}
        </>
    )
}
