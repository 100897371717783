// import { ethers } from 'ethers'
// import abi from './contractABI.json'
import { Contract, Web3Provider } from 'zksync-web3'
import { getWlList, getMerkleProof } from './merkle'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const isPause = async (contract) => {
    const status = await contract.paused()
    return status
}

const getTotalSupply = async () => {
    try {
        const contractAddress = '0x68bae7F492c18b2C2621769bc7AC36Dae906c649'
        const contractAbi = require('./abi/abi.json')
        const provider = new Web3Provider(window.ethereum)
        const contract = new Contract(contractAddress, contractAbi, provider)
        const status = await contract.totalSupply()
        return status.toString()
    } catch (error) {
        console.log(error)
        toast.error(error)
    }
    return 6666
}

// const onMintPressed = async (contract, mintNum, signer) => {
//     if (mintNum <= 0) {
//         return
//     }
//     let address = await signer.getAddress()
//     address = address.toLowerCase()
//     const whiteList = getWlList()

//     const claimedStatus = await contract.getFreeMintStatus(address)
//     const price = await contract.price()
//     if (whiteList.includes(address) && !claimedStatus) {
//         const merkleProof = getMerkleProof(address)
//         const mintTx = await contract.mintWithWhiteList(mintNum, merkleProof, {
//             value: price.mul(mintNum - 1),
//         })
//     } else {
//         const mintTx = await contract.mint(mintNum, {
//             value: price.mul(mintNum),
//         })
//     }
//     await mintTx.wait()

//     console.log('Mint success')
// }

const onMintPressed = async (contract, mintNum, signer) => {
    if (mintNum <= 0) {
        return
    }
    let address = await signer.getAddress()
    address = address.toLowerCase()
    const whiteList = getWlList()

    try {
        const claimedStatus = await contract.getFreeMintStatus(address)
        const price = await contract.price()
        if (whiteList.includes(address) && !claimedStatus) {
            const merkleProof = getMerkleProof(address)
            const mintTx = await contract.mintWithWhiteList(
                mintNum,
                merkleProof,
                {
                    value: price.mul(mintNum - 1),
                }
            )
            await mintTx.wait()
        } else {
            const mintTx = await contract.mint(mintNum, {
                value: price.mul(mintNum),
            })
            await mintTx.wait()
        }
        toast.success('HOOT! welcome to zkBirds')
        console.log('Mint success')
    } catch (error) {
        toast.error(error?.data?.message)
    }
}

export { isPause, getTotalSupply, onMintPressed }
