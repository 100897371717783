import Link from "next/link";
import Image from "next/image"
import { footerMenuList, socialIcons } from "../data/footer_data";
import next from "next";

const footer = () => {
  return (
    <>
      {/* <!-- Footer --> */}

      <footer className="dark:bg-jacarta-900 page-footer bg-white">
        <div className="container">
          <div className="py-10 md:grid-cols-12">
            <div className="flex justify-center space-x-20">
              {socialIcons.map((item) => {
                const { id, href, text } = item;
                return (
                  <Link href={href} key={id}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group cursor-pointer"
                    >
                      <svg className="icon group-hover:fill-accent fill-jacarta-300 h-8 w-8 dark:group-hover:fill-white">
                        <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                      </svg>
                    </a>
                  </Link>
                );
              })}
            </div>
          </div>


          <div className="flex flex-col items-center justify-center space-y-2 pb-8 sm:flex-row sm:space-y-0">
            <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
              <li>
                <Link href="/tarms">
                  <a className="hover:text-accent dark:hover:text-white">
                    Terms and conditions
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/tarms">
                  <a className="hover:text-accent dark:hover:text-white">
                    Privacy policy
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
